import { Controller } from "stimulus"

export default class extends Controller {
  pusher = new Pusher(globals.PUSHER_KEY, { cluster: "mt1" })

  connect() {
    Pusher.logToConsole = true

    this.channel.bind(this.eventName, (data) => {
      if (data.refresh) {
        // Refresh the current page without a full reload. Clearing the cache prevents a flash
        // of the original page content. Use "replace" to avoid adding a new history entry.
        Turbolinks.clearCache()
        Turbolinks.visit(location.toString(), { action: "replace" })
      }
    })
  }

  disconnect() {
    this.pusher.disconnect()
  }

  get channel() {
    this._channel = this._channel || this.pusher.subscribe(this.channelName)
    return this._channel
  }

  get channelName() {
    return this.data.get("channel")
  }

  get eventName() {
    return this.data.get("event")
  }
}
