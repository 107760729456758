import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["points", "minis", "buncos", "pointsDisplay"]

  buncoPoints = 21
  miniPoints = 5

  increment(event) {
    event.preventDefault()
    this.points++
  }

  decrement(event) {
    event.preventDefault()
    this.points--
  }

  bunco(event) {
    event.preventDefault()
    this.buncos++
    this.points += this.buncoPoints
  }

  mini(event) {
    event.preventDefault()
    this.minis++
    this.points += this.miniPoints
  }

  get points() {
    return parseInt(this.pointsTarget.value)
  }

  set points(points) {
    this.pointsTarget.value = points
    this.pointsDisplayTarget.textContent = this.points
  }

  get buncos() {
    return parseInt(this.buncosTarget.value)
  }

  set buncos(buncos) {
    this.buncosTarget.value = buncos
  }

  get minis() {
    return parseInt(this.minisTarget.value)
  }

  set minis(minis) {
    this.minisTarget.value = minis
  }
}
